<template>
    <div class="wrapper">

        <section class="encabezado -section" data="cartas-servicios">
            <h2 class="title -white">Cartas de servicios</h2>
        </section>


        <nav class="breadcrumb">
            <div class="container row-start">
                <router-link v-if="!checkroutepublico()" to="/">Inicio</router-link>
                <router-link v-if="!checkroutepublico()" :to="$t('enlaces.listadocartasservicios')">Sistema de cartas de
                    servicios</router-link>
                <router-link :to="$route.fullPath">{{ carta($route.params.id).carta }}</router-link>
            </div>
        </nav>


        <a @click="back" class="back">Volver</a>
        <!-- <a v-if="!checkroutepublico()" @click="back" class="back">Volver</a> -->

        <div class="intro-carta container row-start -section" v-if="carta && carta($route.params.id)">

            <section class="intro-ficha-indicador container">

                <div class="top">
                    <div class="datos-carta">
                        <h2 class="title -white -left">{{ carta($route.params.id).carta }}</h2>
                    </div>
                    <img v-if="carta($route.params.id).imagen" :src="carta($route.params.id).imagen" class="logo"
                        :alt="carta($route.params.id).carta">
                </div>

                <div class="bottom">
                    <!-- <p v-if="carta($route.params.id).cartadescripcion">{{carta($route.params.id).cartadescripcion}}</p> -->
                    <p v-if="carta($route.params.id).cartadescripcion" v-html=carta($route.params.id).cartadescripcion>
                    </p>
                    <div class="tips-carta">
                        <div>
                            <p v-if="carta($route.params.id).fechaaprobacion"><strong>Aprobación:</strong>
                                {{ carta($route.params.id).fechaaprobacion | parsedate }}</p>
                            <p v-if="carta($route.params.id).fechaultimacertificacion"><strong>Última
                                    certificación:</strong>
                                {{ carta($route.params.id).fechaultimacertificacion | parsedate }}</p>
                            <p><strong>Fecha de evaluación:</strong> {{ carta($route.params.id).fechaevaluacion |
                                parsedate }}
                            </p>

                        </div>
                        <div>
                            <p v-if="carta($route.params.id).unidadresponsable"><strong>Unidad Responsable:</strong>
                                {{ carta($route.params.id).unidadresponsable }}</p>

                            <p v-if="carta($route.params.id).areadeaccion"><strong>Área de Acción:</strong>
                                {{ carta($route.params.id).areadeaccion }}</p>
                            <p><a v-if="carta($route.params.id).UrlEnlace" :href="carta($route.params.id).UrlEnlace"
                                    class="link" target="_blank">Visitar web</a></p>



                        </div>


                    </div>
                </div>

            </section>



        </div>

        <p class="advertencia container" v-html="carta($route.params.id).cartadescripcion2"></p>

        <section class="tabla-areas -section">

            <div class="container">
                <header class="encabezado-tabla-areas row-start">
                    <h3 class="contador -primary" @click="currentType = 'compromisos'"
                        :active="currentType == 'compromisos'">
                        <span class="numero">{{ compromisosorder.length }}</span>
                        <span class="texto">Compromisos</span>
                    </h3>
                    <!-- 
                    <h3 class="contador -primary" @click="type= 'areas'" :active="type == 'areas'">
                        <span class="numero">NH</span>
                        <span class="texto">Áreas de mejora</span>
                    </h3> -->

                    <h3 class="contador -primary" @click="currentType = 'indicadores'"
                        :active="currentType == 'indicadores'">
                        <span class="numero">
                            <count-to :endVal="indicadores($route.params.id).length"></count-to>
                        </span>
                        <span class="texto">Indicadores</span>
                    </h3>
                </header>


                <div class="contenedor-compromisos" v-if="currentType == 'compromisos'">

                    <div class="own-busqueda row-start">
                        <label for="">Buscar:</label>
                        <input type="text" v-model="search">
                    </div>

                    <compromiso v-for="(data, key) in compromisosorder" :data="data" :key="key">
                    </compromiso>

                    <div class="compromiso" v-if="carta($route.params.id).medidassubsanacion">
                        <p v-html="carta($route.params.id).medidassubsanacion"></p>
                    </div>

                </div>

                <resultados :type="'indicadores'" :cansearch="true" :data="indicadores($route.params.id)"
                    v-if="currentType == 'indicadores'"></resultados>


            </div>

        </section>


        <banner-ayudamos :data="['que-son-cartas-servicios', 'cartas-vigentes', 'calidad']"></banner-ayudamos>

    </div>
</template>


<script>

import { mapGetters, mapActions } from 'vuex';

import compromiso from '@/components/parts/compromiso';

import resultados from '@/components/parts/resultados-busqueda'

import moment from 'moment';

import router from '@/router';

export default {
    name: 'ficha-cartas-servicios',
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        nombreCarta: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'compromisos',
        },
    },
    data() {
        return {
            search: '',
            currentType: this.$route.params.type || this.type // Inicializa currentType
        };
    },
    mounted() {
        this.requestCarta(this.$route.params.id);
        this.setData();
        this.requestCompromisos();
        this.requestIndicadoresCartas();

        //console.log("this.$route.params", this.$route.params);

        if (this.$route.params.type) {
            this.currentType = this.$route.params.type;
        }
    },
    methods: {
        ...mapActions([
            'requestCartaEvolucion',
            'requestCarta',
            'requestCompromisos',
            'requestIndicadoresCartas'
        ]),
        setData() {
            this.requestCartaEvolucion(this.$route.params.id).then(r => {
                console.log(r);
            })
        },
        back() {
            router.go(-1);
        },
        checkroutepublico() {
            if (this.$route.name == 'ficha-cartas-servicios-publico' || this.$route.name == 'ficha-indicador-publico') {
                return true;
            } else {
                return false;
            }
            // if (this.$route.path.includes('publico')) {
            //     return true;
            // } else {
            //     return false;
            // }
        }
    },
    computed: {
        ...mapGetters({
            carta: 'getCarta',
            compromisos: 'getCompromisobyCarta',
            indicadores: 'getIndicadoresByCarta'
        }),
        // carta() {
        //     console.log("this", this);
        //     return this.getCarta(this.id);
        // },
        compromisosorder() {
            let compromisos = JSON.parse(JSON.stringify(this.compromisos(this.$route.params.id)));
            compromisos = compromisos.sort((a, b) => a.compromiso > b.compromiso ? 1 : -1);
            compromisos = compromisos.filter(compromiso => compromiso.compromiso.toLowerCase().includes(this.search.toLowerCase()));
            return compromisos;
        }
    },
    components: {
        'compromiso': compromiso,
        'resultados': resultados,
    },
    filters: {
        parsedate(value) {
            // moment.locale('es');
            // return moment(value).format('L');

            if (!value || !moment(value).isValid()) {
                return '';
            }
            return moment(value).format('L');
        }
    }

}
</script>

<style lang="scss"></style>